import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  formatDateUtc,
  formatDateUtcLocal,
  isBeforeDate,
} from '@zola-helpers/client/dist/es/util/dateUtils';

dayjs.extend(objectSupport);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

type AcceptedIntervals = 15 | 30;

export default function generateTimeOptions(
  intervals: AcceptedIntervals = 15
): { label: string; value: string }[] {
  const times = [];
  for (let h = 0; h <= 23; h += 1) {
    times.push({ hour: h, minute: 0 });
    if (intervals === 15) times.push({ hour: h, minute: 15 });
    times.push({ hour: h, minute: 30 });
    if (intervals === 15) times.push({ hour: h, minute: 45 });
  }
  return times.map(({ hour, minute }) => {
    const value = dayjs({ hour, minute });
    return {
      label: value.format('h:mm a'),
      value: value.format('HH:mm:ss'),
    };
  });
}

export const checkIfEndIsBeforeStart = (
  start_date: string,
  start_at: string,
  end_at?: string | null,
  end_date?: string | null
): boolean => {
  const dateString = formatDateUtc(start_date, 'YYYY-MM-DD');
  const endDateString = end_date ? formatDateUtc(end_date, 'YYYY-MM-DD') : dateString;
  const startAt = `${dateString}T${start_at}Z`;
  const endAt = end_at ? `${endDateString}T${end_at}Z` : null;
  if (endAt && isBeforeDate(endAt, startAt)) {
    return true;
  }
  return false;
};

export const formatEventDate = (
  date: string,
  start_at: string,
  end_at?: string | null,
  end_date?: string | null
): { date: string; start_at: string; end_at: string | null; timezone: string } => {
  const dateString = formatDateUtcLocal(date, 'YYYY-MM-DD');
  // need both time and date to build endDateString
  const endDateString =
    end_at && end_date ? formatDateUtcLocal(end_date, 'YYYY-MM-DD') : dateString;
  const startAt = `${dateString}T${start_at}Z`;
  const endAt = end_at ? `${endDateString}T${end_at}Z` : null;
  const timezoneGuess = dayjs.tz.guess();
  return { date: dateString, start_at: startAt, end_at: endAt || null, timezone: timezoneGuess };
};

export const formatDateForEditing = (
  startAt: string,
  endAt?: string | null
): { date: string; start_at: string; end_at?: string; end_date?: string } => {
  const date = formatDateUtc(startAt, 'MM/DD/YYYY');
  const start_at = formatDateUtc(startAt, 'HH:mm:ss');
  const end_at = endAt ? formatDateUtc(endAt, 'HH:mm:ss') : undefined;
  const end_date = endAt ? formatDateUtc(endAt, 'MM/DD/YYYY') : undefined;
  return { date, start_at, end_at, end_date };
};
