import React from 'react';
import styled from '@emotion/styled';

// types
import type { WCmsTravelPageView, WCmsTravelView } from '@zola/svc-web-api-ts-client';

// utils
import { formatDateUtc } from '@zola-helpers/client/dist/es/util/dateUtils';
import getNonHomeCmsHeroImg from 'components/publicWebsiteV2/util/getNonHomeCmsHeroImg';

// context
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';

// components
import { HotelIcon } from '@zola/zola-ui/src/components/SvgIcons/Hotel';
import { HouseRentalIcon } from '@zola/zola-ui/src/components/SvgIcons/HouseRental';
import { AirplaneOutlineIcon } from '@zola/zola-ui/src/components/SvgIcons/AirplaneOutline';
import { CarIcon } from '@zola/zola-ui/src/components/SvgIcons/Car';
import { ShuttleIcon } from '@zola/zola-ui/src/components/SvgIcons/Shuttle';
import { TrainIcon } from '@zola/zola-ui/src/components/SvgIcons/Train';
import { NoteIcon } from '@zola/zola-ui/src/components/SvgIcons/Note';
import TitleImgDesc from 'components/publicWebsiteV2/common/TitleImgDesc';
import { getTravelType } from 'components/manage/EditWebsite/EditWebsiteEntityModal/modals/TravelForm/utils';
import {
  HorizontalColumn,
  Divider,
} from 'components/publicWebsiteV2/util/pageCustomizationOrientation.styles';

// styles
import { SPACING } from '@zola/zola-ui/src/styles/emotion';
import {
  Container,
  TravelDetails,
  Type,
  Name,
  AddressAndPhone,
  StyledAddress,
  sharedSpacing,
  buttonStyles,
} from './Travel.styles';

type TravelProps = {
  pageData?: WCmsTravelPageView;
};

const Travel: React.FC<TravelProps> = ({ pageData }) => {
  const {
    state: {
      components: {
        ThemedButton,
        styleCmsEntityHeaderFont,
        styleCmsEntityBodyFont,
        cmsEntityComponentBodyFontValues,
        styleCmsEntityBodyFont2,
      },
      wedding,
      inPreview,
    },
  } = useWebsiteThemeContext();

  const { title, description, travels, header_image_url, images } = pageData || {};
  const cmsHeaderImgUrl = getNonHomeCmsHeroImg(images);
  const orientation =
    wedding?.public_theme_v2?.components?.CMS_TRAVEL?.option_type_values?.ORIENTATION?.value ||
    'HORIZONTAL';
  const inlineIconStyles = {
    color: `#${cmsEntityComponentBodyFontValues.color}`,
    marginBottom: `${SPACING.S24}`,
    height: '64px',
    width: '64px',
  };

  const iconVisibility =
    wedding?.public_theme_v2?.components?.CMS_TRAVEL?.option_type_values?.SHOW_ICONS?.value ||
    'TRUE';

  const renderIcon = (type: WCmsTravelView['type']) => {
    if (iconVisibility === 'FALSE') return null;
    switch (type) {
      case 'HOTEL':
        return <HotelIcon style={inlineIconStyles} />;
      case 'HOME_RENTAL':
        return <HouseRentalIcon style={inlineIconStyles} />;
      case 'FLIGHT':
        return (
          <AirplaneOutlineIcon viewBox="0 0 26 32" fill="transparent" style={inlineIconStyles} />
        );
      case 'CAR_RENTAL':
        return <CarIcon style={inlineIconStyles} />;
      case 'SHUTTLE':
        return <ShuttleIcon style={inlineIconStyles} />;
      case 'TRAIN':
        return <TrainIcon style={inlineIconStyles} />;
      case 'NOTE':
        return <NoteIcon style={inlineIconStyles} />;
      default:
        return null;
    }
  };

  const StyledType = styleCmsEntityBodyFont2(Type);
  const renderTypeAndIcon = (type: WCmsTravelView['type']) => (
    <>
      {renderIcon(type)}
      <StyledType>{getTravelType(type)}</StyledType>
    </>
  );

  const Para = styled.p`
    ${sharedSpacing};
    text-align: center;
    white-space: pre-wrap;
  `;
  const StyledP = styleCmsEntityBodyFont(Para);

  const showEmptyStateMessage = travels?.length === 0 && !description;
  const StyledName = styleCmsEntityHeaderFont(Name);
  const StyledAddressAndPhone = styleCmsEntityBodyFont(AddressAndPhone);

  return (
    <>
      <TitleImgDesc
        title={title}
        url={cmsHeaderImgUrl || header_image_url}
        description={description}
      />
      {travels && travels.length > 0 && (
        <Container>
          {travels?.map(travel => {
            const handleClickBtn = () => {
              if (inPreview) return;
              if (typeof window !== 'undefined') {
                // DO NOT REMOVE - Required for affiliate tracking
                try {
                  window.vglnk.open(travel.url, '_blank');
                } catch (err) {
                  window.open(travel.url, '_blank', 'noopener, noreferrer')?.focus();
                }
              }
            };
            return (
              <TravelDetails key={travel.id} orientation={orientation}>
                <HorizontalColumn orientation={orientation}>
                  {renderTypeAndIcon(travel.type as string)}
                  <StyledName>{travel.name}</StyledName>
                </HorizontalColumn>
                {orientation === 'HORIZONTAL' && (
                  <Divider
                    style={{ backgroundColor: `#${cmsEntityComponentBodyFontValues.color}` }}
                  />
                )}
                <HorizontalColumn orientation={orientation}>
                  {(travel.state_province || travel.contact_phone) && (
                    <StyledAddressAndPhone>
                      <StyledAddress
                        singleLine={orientation === 'VERTICAL'}
                        addressData={{
                          address1: travel.address_1,
                          address2: travel.address_2,
                          city: travel.city,
                          state: travel.state_province,
                          zip: travel.postal_code,
                          countryCode: travel.country_code,
                        }}
                      />
                      &nbsp;
                      {travel.contact_phone}
                    </StyledAddressAndPhone>
                  )}
                  {travel.start_at && (
                    <StyledP>
                      {formatDateUtc(travel.start_at, 'MMMM D, YYYY h:mm a')}
                      {travel.end_at && formatDateUtc(travel.end_at, '[ - ]h:mm a')}
                    </StyledP>
                  )}
                  {travel.note && <StyledP>{travel.note}</StyledP>}
                  {travel.code && <StyledP>Discount Code - {travel.code}</StyledP>}
                  {travel.url && (
                    <ThemedButton css={buttonStyles} onClick={handleClickBtn}>
                      View
                    </ThemedButton>
                  )}
                </HorizontalColumn>
              </TravelDetails>
            );
          })}
        </Container>
      )}
      {showEmptyStateMessage && (
        <StyledP>
          We&apos;re working on gathering some recommendations for our out-of-towner guests. Stay
          tuned!
        </StyledP>
      )}
    </>
  );
};

export default Travel;
